import CountUp from "react-countup";

const Balance = ({ value, prefix = "", decimals = 2, suffix = "", separator = "," }) => {
  // const previousValue = useRef(0);

  // useEffect(() => {
  //   previousValue.current = value;
  // }, [value]);
  return (
    <CountUp
      start={value}
      end={value}
      decimals={decimals}
      separator={separator}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export default Balance;
