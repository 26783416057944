/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import { Box, useMediaQuery } from "@mui/material";
import { scroller } from "react-scroll";
import { BUYBACK_ADDR, TOKEN_ADDRS } from "../../abis/address";

const Footer = () => {
  const socials = [
    {
      url: "/images/footer/telegram.png",
      link: "https://t.me/WPTInvestingCorp",
    },
    {
      url: "/images/footer/twitter.png",
      link: "https://x.com/wptinvesting?s=21",
    },
    {
      url: "/images/footer/mail.png",
      link: "mailto:Support@wptinvestingcorp.io",
    },
    {
      url: "/images/footer/github.png",
      link: "https://github.com/Wptceo",
    },
    {
      url: "/images/footer/youtube.png",
      link: "https://youtube.com/@wptinvestingcorp",
    },
    // {
    //   url: "/images/footer/reddit.png",
    //   link: "https://www.tiktok.com/@wptinvestingcorp",
    // },
  ];

  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 1000px)");
  const sm = useMediaQuery("(max-width : 700px)");
  const xs = useMediaQuery("(max-width : 400px)");

  const qlinks = ["Overview", "About", "Ecosystem", "Mission"];

  const makeSocial = () => {
    return (
      <Box>
        <Box fontWeight={500} fontSize={lg ? "calc(100vw / 1440 * 32)" : xs ? "24px" : "32px"}>
          Join our Community
        </Box>
        <SocialPanel display={"flex"} mt={lg ? "calc(100vw / 1440 * 18)" : "18px"}>
          {socials.map((data, i) => {
            return (
              <a href={data.link} target={"_blank"} rel="noreferrer" key={i}>
                <SocialItem
                  href={data.link}
                  target={"_blank"}
                  minWidth={
                    i > 1
                      ? lg
                        ? "calc(100vw / 1440 * 31)"
                        : xs
                        ? "25px"
                        : "31px"
                      : lg
                      ? "calc(100vw / 1440 * 37)"
                      : xs
                      ? "28px"
                      : "37px"
                  }
                  minHeight={lg ? "calc(100vw / 1440 * 31)" : xs ? "25px" : "31px"}
                  maxWidth={
                    i > 1
                      ? lg
                        ? "calc(100vw / 1440 * 31)"
                        : "25px"
                      : lg
                      ? "calc(100vw / 1440 * 37)"
                      : "28px"
                  }
                  maxHeight={lg ? "calc(100vw / 1440 * 31)" : xs ? "25px" : "31px"}
                  key={i}
                >
                  <img src={data.url} width={"100%"} height={"100%"} alt={""} />
                </SocialItem>
              </a>
            );
          })}
        </SocialPanel>
      </Box>
    );
  };
  return (
    <StyledContainer>
      <Box display={"flex"} justifyContent={"space-between"}>
        {!sm ? (
          <Box
            display={"flex"}
            minWidth={lg ? "calc(100vw / 1440 * 282)" : "282px"}
            minHeight={lg ? "calc(100vw / 1440 * 157)" : "157px"}
            maxWidth={lg ? "calc(100vw / 1440 * 282)" : "282px"}
            maxHeight={lg ? "calc(100vw / 1440 * 157)" : "157px"}
          >
            <img src={"/logotext.png"} width={"100%"} height={"100%"} alt={""} />
          </Box>
        ) : (
          ""
        )}
        <Box fontSize={lg ? "calc(100vw / 1440 * 16)" : "16px"} lineHeight={"238%"}>
          <Box
            fontSize={lg ? "calc(100vw / 1440 * 20)" : "20px"}
            fontWeight={"bold"}
            lineHeight={"150%"}
            mb={lg ? "calc(100vw / 1440 * 20)" : "20px"}
          >
            Quick Links
          </Box>
          {qlinks.map((data, i) => {
            return (
              <ItemLink
                key={i}
                onClick={() =>
                  scroller.scrollTo(data, {
                    duration: 1000,
                    delay: 0,
                    smooth: true,
                  })
                }
              >
                {data}
              </ItemLink>
            );
          })}
        </Box>

        <Box fontSize={lg ? "calc(100vw / 1440 * 16)" : "16px"} lineHeight={"238%"}>
          <Box
            fontSize={lg ? "calc(100vw / 1440 * 20)" : "20px"}
            fontWeight={"bold"}
            lineHeight={"150%"}
            mb={lg ? "calc(100vw / 1440 * 20)" : "20px"}
          >
            Resources
          </Box>
          <a
            href={`https://etherscan.io/address/${TOKEN_ADDRS["wpt"]}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <ItemLink> WPT Token Contract</ItemLink>
          </a>
          <a
            href={`https://etherscan.io/address/${TOKEN_ADDRS["bacon"]}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <ItemLink> Bacon Token Contract</ItemLink>
          </a>
          <a
            href={`https://etherscan.io/address/${BUYBACK_ADDR}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <ItemLink>Treasury Contract</ItemLink>
          </a>
        </Box>
        {!md ? makeSocial() : ""}
      </Box>
      {md ? (
        <Box mt={"80px"} display={"flex"} justifyContent={"flex-end"}>
          {makeSocial()}
        </Box>
      ) : (
        ""
      )}
      <Box
        mt={lg ? "calc(100vw / 1440 * 158)" : md ? "80px" : "158px"}
        fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
      >
        ©2022 WPT Investing Corp. All rights reserved
      </Box>
    </StyledContainer>
  );
};

const SocialPanel = styled(Box)`
  > a:last-child > div {
    margin-right: 0;
  }
`;

const SocialItem = styled(Box)`
  display: flex;
  margin-right: calc(100vw / 1440 * 32);
  transition: all 0.3s;
  opacity: 0.7;
  transform: scale(0.8, 0.8);
  :hover {
    transform: scale(0.9, 0.9);
    opacity: 1;
  }
  @media screen and (max-width: 1440px) {
    margin-right: 32px;
  }
  @media screen and (max-width: 400px) {
    transform: scale(0.9, 0.9);
    :hover {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
`;

const ItemLink = styled(Box)`
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    color: #3671e9;
  }
`;

const StyledContainer = styled(Box)`
  padding: calc(100vw / 1440 * 23) calc(100vw / 1440 * 126) calc(100vw / 1440 * 80)
    calc(100vw / 1440 * 81);
  color: white;
  @media screen and (max-width: 1100px) {
    padding: 23px 30px 80px 30px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  overflow: hidden;
`;

export default Footer;
