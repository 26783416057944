/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import { Element } from "react-scroll";
import { AiOutlineCopy } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Balance from "../../components/Balance";
import { TOKEN_ADDRS } from "../../abis/address";
import useProjectInfo from "../../hooks/useProjectInfo";

function Future({ data }) {
  const { curToken } = useProjectInfo();
  const [isCopied, setIsCopied] = useState(false);
  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 960px)");
  const sm = useMediaQuery("(max-width : 500px)");

  const onCopyAddress = (address) => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    navigator.clipboard.writeText(address);
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <Element name={"Overview"}>
      <StyledContainer>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          position={"relative"}
          zIndex={10}
          flexDirection={md ? "column-reverse" : "row"}
          alignItems={"center"}
        >
          <Box data-aos="fade-up" data-aos-delay="300">
            <PricePanel mt={lg ? "calc(100vw / 1440 * 64)" : "64px"}>
              <Box>
                <Balance prefix="$" value={data[curToken].price} decimals={2} />
              </Box>
              <Box>{curToken.toUpperCase()} Token Price</Box>
            </PricePanel>
            <CopyAddressPanel
              onClick={() => onCopyAddress(TOKEN_ADDRS[curToken])}
              copied={isCopied}
            >
              <Box component={"img"} src="/icons/etherscan.svg" height={"100%"} />
              <Box overflow={"hidden"} textOverflow={"ellipsis"} mx={"10px"}>
                {isCopied ? "ADDRESS COPIED" : TOKEN_ADDRS[curToken]}
              </Box>
              <AiOutlineCopy />
            </CopyAddressPanel>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 64)" : sm ? "32px" : "42px"}
              fontWeight={"bold"}
              maxWidth={lg ? "clg ? alc(100vw / 1440 * 588)" : md ? "600px" : "480px"}
              mt={lg ? "calc(100vw / 1440 * 24)" : md ? "30px" : "16px"}
              lineHeight={"118%"}
            >
              The future of crypto backed by real world utility.
            </Box>
            <Box
              lineHeight={"175%"}
              fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
              maxWidth={lg ? "calc(100vw / 1440 * 435)" : md ? "600px" : "400px"}
              mt={md ? "10px" : 0}
              color={"#E0E0E0"}
            >
              Cryptocurrency is changing the world and the lives of many people that invest in it
              every day; however, corporate America and businesses in the US have yet to fully
              utilize it to its benefit. WPT is directly addressing this as we are creating a bridge
              between corporate America and blockchain by partnering with top MCA Prime Lenders.
            </Box>
            <Box mt={lg ? "calc(100vw / 1440 * 40)" : "26px"}>
              <a href={"https://youtube.com/@wptinvestingcorp"} target={"_blank"} rel="noreferrer">
                <Button
                  type={"arrow1"}
                  width={lg ? "calc(100vw / 1440 * 262)" : sm ? "216px" : "262px"}
                  height={lg ? "calc(100vw / 1440 * 64)" : sm ? "50px" : "64px"}
                  fontSize={lg ? "calc(100vw / 1440 * 18)" : sm ? "14px" : "18px"}
                >
                  Presentation Video
                </Button>
              </a>
            </Box>
          </Box>
          <Box
            data-aos="fade-up"
            data-aos-delay="300"
            display={"flex"}
            mt={lg ? "calc(-100vw / 1440 * 16)" : "50px"}
            minWidth={lg ? "calc(100vw / 1440 * 604)" : sm ? "375px" : "540px"}
            maxWidth={lg ? "calc(100vw / 1440 * 604)" : sm ? "375px" : "540px"}
            height={"100%"}
          >
            <ReactPlayer
              className="react-player fixed-bottom"
              url="/images/future/vector.mp4"
              width="100%"
              height="100%"
              controls={false}
              loop={true}
              playing={true}
              autoPlay={true}
              muted={true}
              playsinline={true}
            />
          </Box>
        </Box>
        <Vector1 />
        <Vector2 />
      </StyledContainer>
    </Element>
  );
}

const Vector1 = styled(Box)`
  background-image: url("/images/future/vector1.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 925);
  height: calc(100vw / 1440 * 603);
  position: absolute;
  right: calc(100vw / 1440 * 385);
  top: calc(100vw / 1440 * 215);
  @media screen and (max-width: 1440px) {
    width: 616px;
    height: 402px;
    right: 257px;
    top: 143px;
  }
`;

const Vector2 = styled(Box)`
  background-image: url("/images/future/vector2.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 840);
  height: calc(100vw / 1440 * 380);
  position: absolute;
  right: 0;
  top: calc(100vw / 1440 * 300);
  @media screen and (max-width: 1440px) {
    width: 560px;
    height: 253px;
    top: 200px;
  }
`;

const CopyAddressPanel = styled(Box)`
  cursor: pointer;
  background: ${({ copied }) => (copied ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.1)")};
  transition: all 0.3s;
  border-radius: calc(100vw / 1440 * 32);
  width: calc(100vw / 1440 * 262);
  height: calc(100vw / 1440 * 40);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(100vw / 1440 * 4);
  font-size: calc(100vw / 1440 * 16);
  line-height: 175%;
  margin-top: calc(100vw / 1440 * 6);
  padding-right: calc(100vw / 1440 * 10);

  > svg {
    min-width: calc(100vw / 1440 * 16) !important;
    min-height: calc(100vw / 1440 * 16) !important;
  }

  @media screen and (max-width: 1440px) {
    border-radius: 21px;
    width: 262px;
    height: 40px;
    padding: 4px;
    font-size: 16px;
    margin-top: 6px;
    padding-right: 10px;

    > svg {
      min-width: 16px !important;
      min-height: 16px !important;
    }
  }
`;

const PricePanel = styled(Box)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: calc(100vw / 1440 * 32);
  width: calc(100vw / 1440 * 262);
  height: calc(100vw / 1440 * 40);
  display: flex;
  padding: calc(100vw / 1440 * 4);
  font-size: calc(100vw / 1440 * 16);
  > div:nth-child(1) {
    background: white;
    color: #0d0d2b;
    font-weight: 500;
    border-radius: calc(100vw / 1440 * 32);
    width: calc(100vw / 1440 * 78);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:nth-child(2) {
    padding: calc(100vw / 1440 * 3) 0 calc(100vw / 1440 * 1) calc(100vw / 1440 * 15);
    line-height: 175%;
  }
  @media screen and (max-width: 1440px) {
    border-radius: 21px;
    width: 262px;
    height: 40px;
    padding: 4px;
    font-size: 16px;
    > div:nth-child(1) {
      border-radius: 32px;
      width: 78px;
    }
    > div:nth-child(2) {
      padding: 2px 0 0px 10px;
    }
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 120);
  position: relative;
  @media screen and (max-width: 1440px) {
    padding: 0 120px;
  }
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default Future;
