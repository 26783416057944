/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Element } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Team() {
  const teams = [
    {
      url: "/images/team/team1.png",
      name: "JONATHAN MENJIVAR",
      position: "CEO & Founder",
      link: "https://x.com/cryptoflow101"
    },
    {
      url: "/images/team/team2.png",
      name: "Nahla Kamaluddin, Esq.",
      position: "COO & Attorney",
      link: "https://x.com/attorneynahla"
    },
  ];
  const lg = useMediaQuery("(min-width : 1440px)");
  const sm = useMediaQuery("(max-width : 500px)");
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <Element name={"Mission"}>
      <StyledContainer>
        <MissionPanel data-aos="fade-up" data-aos-delay={300}>
          <Box
            fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "24px" : "32px"}
            fontWeight={"bold"}
          >
            Our Mission.
          </Box>
          <Box
            mt={lg ? "calc(100vw / 1440 * 15)" : "15px"}
            fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
            lineHeight={"175%"}
          >
            Our ecosystem and the utilities we provide are one of a kind and
            sets us apart from any other token on the market today. We are
            excited to finally bring blockchain and crypto to Corporate America
            and conquer it as we continue to secure more and more partnerships
            in the MCA lending space and the business world. And this is all
            just the beginning of our ever-growing project. WPT Investing Corp
            will pave the way for blockchain technology and businesses today.
          </Box>
          <EthereumVector />
        </MissionPanel>
        <Box
          textAlign={"center"}
          mt={lg ? "calc(100vw / 1440 * 36)" : "36px"}
          fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "24px" : "32px"}
          fontWeight={"bold"}
          data-aos="fade-up"
          data-aos-delay={300}
        >
          Our Team
        </Box>
        <TeamPanel mt={lg ? "calc(100vw / 1440 * 28)" : "28px"}>
          {teams.map((data, i) => {
            return (
              <TeamItem data-aos="fade-up" data-aos-delay={300 * i} key={i}>
                <a href={data.link} target={"_blank"} rel="noreferrer" style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                  <Box
                    display={"flex"}
                    minWidth={
                      lg ? "calc(100vw / 1440 * 115)" : sm ? "80px" : "115px"
                    }
                    minHeight={
                      lg ? "calc(100vw / 1440 * 115)" : sm ? "80px" : "115px"
                    }
                    maxWidth={
                      lg ? "calc(100vw / 1440 * 115)" : sm ? "80px" : "115px"
                    }
                    maxHeight={
                      lg ? "calc(100vw / 1440 * 115)" : sm ? "80px" : "115px"
                    }
                  >
                    <img src={data.url} width={"100%"} height={"100%"} alt={""} />
                  </Box>
                  <Box
                    minWidth={lg ? "calc(100vw / 1440 * 24)" : "24px"}
                    minHeight={lg ? "calc(100vw / 1440 * 24)" : "24px"}
                    maxWidth={lg ? "calc(100vw / 1440 * 24)" : "24px"}
                    maxHeight={lg ? "calc(100vw / 1440 * 24)" : "24px"}
                    my={lg ? "calc(100vw / 1440 * 13)" : "13px"}
                  >
                    <img
                      src={"/images/team/linkedin.svg"}
                      width={"100%"}
                      height={"100%"}
                      alt={""}
                    />
                  </Box>
                  <Box
                    fontWeight={500}
                    letterSpacing={"1px"}
                    textAlign={"center"}
                  >
                    {data.name.toUpperCase()}
                  </Box>
                  <Box textAlign={"center"}>{data.position}</Box>
                </a>
              </TeamItem>
            );
          })}
        </TeamPanel>
      </StyledContainer>
    </Element>
  );
}

const TeamPanel = styled(Box)`
  display: flex;
  > div {
    margin-right: calc(100vw / 1440 * 55);
  }
  > div:last-child {
    margin: 0;
  }
  width: fit-content;
  margin: 0 auto;
  @media screen and (max-width: 1440px) {
    > div {
      margin-right: 55px;
    }
  }
  @media screen and (max-width: 750px) {
    > div {
      margin-right: 0;
      margin-bottom: 30px;
    }
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

const TeamItem = styled(Box)`
  width: calc(100vw / 1440 * 194);
  font-size: calc(100vw / 1440 * 16);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1440px) {
    width: 194px;
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`;

const EthereumVector = styled(Box)`
  position: absolute;
  left: calc(100vw / 1440 * 21);
  top: calc(-100vw / 1440 * 40);
  background-image: url("/images/holders/ethereumvector.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 155);
  height: calc(100vw / 1440 * 244);
  @media screen and (max-width: 1440px) {
    left: 21px;
    top: -40px;
    width: 155px;
    height: 244px;
  }
`;

const MissionPanel = styled(Box)`
  padding: 45px 60px 40px 46px;
  background: #3671e9;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 136);
  position: relative;
  background: linear-gradient(180deg, #2b076e 0%, #0d0d2b 100%);
  padding-top: calc(100vw / 1440 * 95);
  padding-bottom: calc(100vw / 1440 * 119);
  position: relative;
  @media screen and (max-width: 1100px) {
    padding: 95px 30px 119px 30px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  overflow: hidden;
`;

export default Team;
