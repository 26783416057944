/* eslint-disable no-new-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { multicall } from "../utils/contracts";
import MultiCallABI from "../abis/MultiCallABI.json";
import { BUYBACK_ADDR, MULTICALL_ADDR, TOKEN_ADDRS, TOKEN_LIST } from "../abis/address";
import axios from "axios";
import WPTABI from "../abis/WPTABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import BACONABI from "../abis/BACONABI.json";
import useRefresh from "./useRefresh";

const defaultVal = {
  ethPrice: 0,
  treasuryETHBalance: 0,
  treasuryBalance: 0,
  totalBalance: 0,
  priceHistory: [],
  fees: {},
  data: {},
  buyBackInfo: {},
};

export const TokenInfoContext = React.createContext(defaultVal);

export default function useTokenInfo() {
  return React.useContext(TokenInfoContext);
}
const apiKeyList = [
  "82fc55c0-9833-4d12-82bb-48ae9748bead",
  "10760947-8c9a-4a18-b20f-2be694baf496",
  "4853da0a-f79f-4714-a915-d683b8168e1e",
  "4f616412-ca6d-4876-9a94-dac14e142b12",
];

const WETH_ADDR = {
  1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  56: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
};

const SUBGRAPH_URL = process.env.REACT_APP_SUBGRAPH;

export function TokenInfoProvider({ children }) {
  const [data, setData] = useState({
    wpt: { totalSupply: "10000000" },
    bacon: { totalSupply: "1000000" },
  });
  const [ethPrice, setETHPrice] = useState(0);
  const [treasuryETHBalance, setTreasuryETHBalance] = useState(0);
  const [fees, setFees] = useState({
    wpt: { buyFee: 0, sellFee: 0 },
    bacon: { buyFee: 0, sellFee: 0 },
  });
  const [priceHistory, setPriceHistory] = useState({ wpt: [], bacon: [] });
  const [buyBackInfo, setBuyBackInfo] = useState({ total: 0, count: 0, average: 0 });
  const [totalBalance, setTotalBalance] = useState(0);
  const { fastRefresh } = useRefresh();

  async function fetchPrice(address, chainID, resolution) {
    const to = Math.floor(Date.now() / 1000);
    const url = `https://api.dex.guru/v1/tradingview/history?symbol=${
      address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" || !address ? WETH_ADDR[1] : address
    }-${chainID === 56 ? "bsc" : "eth"}_USD&resolution=${resolution}&from=${
      to - 3600 * 24
    }&to=${to}`;
    let result = await axios.get(url);
    return result;
  }

  async function fetchPriceHistory() {
    let i;
    for (i = 0; i < apiKeyList.length; i++) {
      const response = await fetch(
        new Request("https://api.livecoinwatch.com/coins/single/history"),
        {
          method: "POST",
          headers: new Headers({
            "content-type": "application/json",
            "x-api-key": apiKeyList[i],
          }),
          body: JSON.stringify({
            currency: "USD",
            code: "WPT",
            start: Date.now() - 1000 * 3600 * 24,
            end: Date.now(),
          }),
        }
      );
      let result = await response.json();
      if (!result.history) continue;
      result = result.history;
      let temp = [];
      for (let i = 0; i < result.length; i++) {
        temp.push(result[i].rate);
      }
      return temp;
    }
    if (i === apiKeyList.length) return [];
  }

  async function fetchBuyBack() {
    const response = await axios.post(SUBGRAPH_URL, {
      query: `{
        tokenBuyBack(id:"Total") {
          totalAmountETH
          totalCount
        }
        tokenBuyBackForTokenB(id:"Total") {
          totalAmountETH
          totalCount
        }
      }`,
    });
    return response.data.data;
  }

  async function fetchData() {
    try {
      const ethBalanceCalls = [
        {
          name: "getEthBalance",
          address: MULTICALL_ADDR,
          params: [BUYBACK_ADDR],
        },
      ];
      const feeCells = ["wpt", "bacon"].map((item) => [
        {
          name: "getBaseBuyFees",
          address: TOKEN_ADDRS[item],
        },
        {
          name: "getBaseSellFees",
          address: TOKEN_ADDRS[item],
        },
      ]);

      let treasuryCalls = [];
      for (let i = 0; i < TOKEN_LIST.length; i++) {
        treasuryCalls.push({
          name: "balanceOf",
          address: TOKEN_ADDRS[TOKEN_LIST[i]],
          params: [BUYBACK_ADDR],
        });
      }

      const _data = await Promise.all([
        multicall(MultiCallABI, ethBalanceCalls),
        multicall(ERC20ABI, treasuryCalls),
        fetchPrice(WETH_ADDR[1], 1, 10),
        Promise.all(
          TOKEN_LIST.map(async (data) => {
            const priceResult = await fetchPrice(TOKEN_ADDRS[data], 1, 60);
            return priceResult.data.c;
            // const priceList = priceResult.data.c;
            // return priceList[priceList.length - 1];
          })
        ),
        multicall(WPTABI, feeCells[0]),
        multicall(BACONABI, feeCells[1]),
        fetchPriceHistory(),
        fetchBuyBack(),
      ]);

      const balances = _data[1];
      const ethPrice = _data[2].data.c;
      const prices = _data[3];
      const wptFees = _data[4];
      const baconFees = _data[5];
      const buybacks = _data[7];

      const temp = new Object();
      let _totalBalance = 0;
      for (let i = 0; i < TOKEN_LIST.length; i++) {
        const price = prices[i][prices[i].length - 1];
        temp[TOKEN_LIST[i]] = {
          balance: balances[i] / Math.pow(10, 18),
          price: price,
          balanceValue: (balances[i] / Math.pow(10, 18)) * price,
          balancePercent: (balances[i] / Math.pow(10, 18) / data[TOKEN_LIST[i]].totalSupply) * 100,
          marketCap: price * data[TOKEN_LIST[i]].totalSupply,
          ...data[TOKEN_LIST[i]],
        };
        _totalBalance += (balances[i] / Math.pow(10, 18)) * price;
      }
      const total =
        buybacks.tokenBuyBack.totalAmountETH / Math.pow(10, 18) +
        buybacks.tokenBuyBackForTokenB.totalAmountETH / Math.pow(10, 18);

      const count = buybacks.tokenBuyBack.totalCount + buybacks.tokenBuyBackForTokenB.totalCount;

      setETHPrice(ethPrice[ethPrice.length - 1]);
      setTreasuryETHBalance(_data[0][0][0] / Math.pow(10, 18));
      setPriceHistory({ wpt: prices[0], bacon: prices[1] });
      setBuyBackInfo({ total, count, average: total / count });
      setTotalBalance(_totalBalance);

      let wptBuyFee = 0,
        wptSellFee = 0,
        baconBuyFee = 0,
        baconSellFee = 0;
      for (let i = 0; i < 4; i++) {
        wptBuyFee += wptFees[0][i] / 1;
        wptSellFee += wptFees[1][i] / 1;
      }
      for (let i = 0; i < 5; i++) {
        baconBuyFee += baconFees[0][i] / 1;
        baconSellFee += baconFees[1][i] / 1;
      }
      setFees({
        wpt: {
          buyFee: wptBuyFee,
          sellFee: wptSellFee,
        },
        bacon: {
          buyFee: baconBuyFee,
          sellFee: baconSellFee,
        },
      });
      setData(temp);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [fastRefresh]);

  return (
    <TokenInfoContext.Provider
      value={{
        ethPrice,
        treasuryETHBalance,
        treasuryBalance: treasuryETHBalance * ethPrice,
        priceHistory,
        buyBackInfo,
        totalBalance,
        fees,
        data,
      }}
      children={children}
    />
  );
}
