/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import Future from "./Future";
import About from "./About";
import Profit from "./Profit";
import Treasury from "./Treasury";
import Holders from "./Holders";
import Sharing from "./Sharing";
import RoadMap from "./RoadMap";
import Team from "./Team";
import useTokenInfo from "../../hooks/useTokenInfo";

function Home() {
  const {
    treasuryETHBalance,
    treasuryBalance,
    priceHistory,
    buyBackInfo,
    totalBalance,
    fees,
    data,
  } = useTokenInfo();
  return (
    <StyledContainer>
      <Future data={data} />
      <About data={data} />
      <Profit
        data={data}
        buyBackInfo={buyBackInfo}
        totalBalance={totalBalance}
        priceHistory={priceHistory}
      />
      <Treasury
        fees={fees}
        treasuryETHBalance={treasuryETHBalance}
        treasuryBalance={treasuryBalance}
      />
      <Holders data={data} priceHistory={priceHistory} />
      <Sharing />
      <RoadMap />
      <Team />
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)`
  color: white;
  overflow: hidden;
`;

export default Home;
