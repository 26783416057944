import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { TokenInfoProvider } from "./hooks/useTokenInfo";
import { RefreshContextProvider } from "./context/RefreshContext";
import { Web3ContextProvider } from "./hooks/web3Context";
import { ProjectInfoProvider } from "./hooks/useProjectInfo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RefreshContextProvider>
      <ProjectInfoProvider>
        <Web3ContextProvider>
          <TokenInfoProvider>
            <App />
          </TokenInfoProvider>
        </Web3ContextProvider>
      </ProjectInfoProvider>
    </RefreshContextProvider>
  </React.StrictMode>
);
