import { BrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";

import "./App.css";
import Notification from "./components/Notification";

function App() {

  return (
    <BrowserRouter>
      <TopBar />
      <Home />
      <Footer />
      <Notification />
    </BrowserRouter>
  );
}

export default App;
