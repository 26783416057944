/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Balance from "../../components/Balance";

function Profit({ data, buyBackInfo, totalBalance, priceHistory }) {
  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 1024px)");
  const sm = useMediaQuery("(max-width : 500px)");

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const porkchops = [
    {
      logo: "/images/treasury/wpt.png",
      title: (
        <div>
          <Balance value={data["wpt"].balance} />
          &nbsp; WPT secured in Porkchop
        </div>
      ),
      detail1: (
        <div>
          <Balance prefix="$" value={data["wpt"].balanceValue} />
          &nbsp; in USD value.
        </div>
      ),
      detail2: (
        <div>
          <Balance value={data["wpt"].balancePercent} />% of total WPT token supply.
        </div>
      ),
    },
    {
      logo: "/images/treasury/bacon.png",
      title: (
        <div>
          <Balance value={data["bacon"].balance} />
          &nbsp; BACON secured in Porkchop
        </div>
      ),
      detail1: (
        <div>
          <Balance prefix="$" value={data["bacon"].balanceValue} />
          &nbsp; in USD value.
        </div>
      ),
      detail2: (
        <div>
          <Balance value={data["bacon"].balancePercent} />% of total BACON token supply.
        </div>
      ),
    },
    {
      logo: "/images/treasury/ham.png",
      title: "0.00 HAM secured in Porkchop.",
      detail1: "Pending...",
      detail2: "Pending...",
    },
    {
      logo: "/images/treasury/eth.svg",
      title: (
        <div>
          <Balance value={buyBackInfo.total} /> ETH In total BUY BACKS by Porkchop.
        </div>
      ),
      detail1: (
        <div>
          <Balance value={buyBackInfo.average} /> Average ETH buy back
        </div>
      ),
      detail2: (
        <div>
          <Balance value={buyBackInfo.count} decimals={0} /> Buy back events since Porkchop release.
        </div>
      ),
    },
  ];
  return (
    <StyledContainer>
      <Box
        fontWeight={"bold"}
        fontSize={lg ? "calc(100vw / 1440 * 40)" : sm ? "28px" : "40px"}
        lineHeight={"150%"}
        textAlign={sm ? "unset" : "center"}
        data-aos="fade-up"
        data-aos-delay={300}
      >
        Innovative Corporate ROI Protocol
      </Box>
      <Box
        fontSize={lg ? "calc(100vw / 1440 * 18)" : sm ? "14px" : "18px"}
        lineHeight={"150%"}
        maxWidth={lg ? "calc(100vw / 1440 * 622)" : "622px"}
        color={"#E0E0E0"}
        mt={lg ? "calc(100vw / 1440 * 6)" : "6px"}
        textAlign={sm ? "unset" : "center"}
        mx={"auto"}
        data-aos="fade-up"
        data-aos-delay={300}
      >
        Through Corporate Earned Taxes as a result of trading volume. WPT Investing Corp can then
        syndicate these fund with real world Prime MCA Lenders. This will allow WPT’s Corporate ROI
        to be used for constant buy backs of WPT Token via our Rewards Treasury (Porkchop).
        Therefore, raising the floor price and taking tokens out of circulation to reward WPT Token
        Holders and its ecosystem.
      </Box>
      <InputPanel data-aos="flip-down">
        <Box
          className="font-bold sm:text-left text-center"
          fontSize={lg ? "calc(100vw / 1920 * 32)" : sm ? "24px" : "32px"}
        >
          Porkchop WPT on Chain Treasury.
        </Box>
        <Box
          className="font-medium text-[#3671E9] uppercase sm:text-left text-center"
          fontSize={lg ? "calc(100vw / 1920 * 16)" : sm ? "12px" : "16px"}
          mt={sm ? "8px" : "0px"}
          maxWidth={sm ? "220px" : "fit-content"}
          mx={sm ? "auto" : "0"}
        >
          Secure & audited Treasury Smart contract. TVL{" "}
          <Balance value={totalBalance} decimals={2} prefix={"$"} />
        </Box>
        <Box
          ml={lg ? "calc(100vw / 1920 * 12)" : md ? "0" : "12px"}
          mt={lg ? "calc(100vw / 1920 * 40)" : sm ? "20px" : "40px"}
        >
          {porkchops.map((data, i) => {
            return (
              <Box
                className="flex sm:flex-row flex-col sm:items-start items-center"
                key={i}
                mt={lg ? "calc(100vw / 1920 * 14)" : "14px"}
              >
                <Box
                  className="flex justify-center items-center"
                  width={lg ? "calc(100vw / 1920 * 75)" : "75px"}
                  height={lg ? "calc(100vw / 1920 * 75)" : "75px"}
                  mr={lg ? "calc(100vw / 1920 * 57)" : md ? (sm ? "0" : "20px") : "57px"}
                >
                  <img
                    src={data.logo}
                    style={{ transform: lg ? `scale(${window.innerWidth / 1920})` : "scale(1)" }}
                  />
                </Box>
                <Box
                  mt={lg ? "calc(100vw / 1920 * 12)" : "12px"}
                  className="sm:text-left text-center"
                >
                  <Box
                    className="font-bold"
                    fontSize={lg ? "calc(100vw / 1920 * 24)" : sm ? "18px" : "24px"}
                    maxWidth={sm ? "220px" : "fit-content"}
                    mx={"auto"}
                  >
                    {data.title}
                  </Box>
                  <Box
                    className="text-[#828282] leading-[175%]"
                    fontSize={lg ? "calc(100vw / 1920 * 16)" : sm ? "12px" : "16px"}
                  >
                    {data.detail1}
                  </Box>
                  <Box
                    className="text-[#828282] leading-[175%]"
                    fontSize={lg ? "calc(100vw / 1920 * 16)" : sm ? "12px" : "16px"}
                  >
                    {data.detail2}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        <img
          src={"/images/treasury/porkchop.png"}
          alt={""}
          className="absolute right-8 top-8 lg:block hidden"
        />
      </InputPanel>
    </StyledContainer>
  );
}

const InputPanel = styled(Box)`
  max-width: 967px;
  width: 100%;
  background: white;
  border-radius: 16px;
  padding: 19px 0 26px 96px;
  margin: 0 auto;
  margin-top: 34px;
  color: #0d0d2b;
  position: relative;
  box-shadow: 0px 80px 40px #3917770f;
  mix-blend-mode: overlay;
  @media screen and (min-width: 1920px) {
    max-width: calc(100vw / 1920 * 967);
    border-radius: calc(100vw / 1920 * 16);
    padding: calc(100vw / 1920 * 19) 0 calc(100vw / 1920 * 26) calc(100vw / 1920 * 96);
    margin-top: calc(100vw / 1920 * 34);
    box-shadow: 0px calc(100vw / 1920 * 80) calc(100vw / 1920 * 40) #3917770f;
  }
  @media screen and (max-width: 1024px) {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 120);
  padding-top: calc(100vw / 1440 * 100);
  position: relative;
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 100px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  z-index: 10;
`;

export default Profit;
