/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Element } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";

function Sharing() {
  const sharings = [
    "Vesting periods for intial holders",
    "Third party transparent team",
    "Liquidity locked for 12 months",
    "Verified launch on Uniswap",
  ];
  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 1150px)");
  const sm = useMediaQuery("(max-width : 550px)");
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <Element name="Ecosystem">
      <StyledContainer>
        <Box
          display={"flex"}
          minWidth={lg ? "calc(100vw / 1440 * 614)" : sm ? "350px" : "550px"}
          minHeight={lg ? "calc(100vw / 1440 * 599)" : sm ? "350px" : "540px"}
          maxWidth={lg ? "calc(100vw / 1440 * 614)" : sm ? "350px" : "550px"}
          maxHeight={lg ? "calc(100vw / 1440 * 599)" : sm ? "350px" : "540px"}
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <img
            src={"/images/sharing/chart.png"}
            width={"100%"}
            height={"100%"}
            alt={""}
          />
        </Box>
        <Box
          maxWidth={lg ? "calc(100vw / 1440 * 408)" : md ? "600px" : "420px"}
          mt={"40px"}
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <Box
            fontSize={lg ? "calc(100vw / 1440 * 28)" : sm ? "23px" : "28px"}
            fontWeight={"bold"}
          >
            Sophisticated Corporate ROI.
          </Box>
          <Box
            color={"#E0E0E0"}
            fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
            lineHeight={"175%"}
            mt={"5px"}
          >
            Rest assured that our token holders are in great hands. Our team is
            fully doxxed. Our COO is a licensed attorney. Our CEO has been a
            registered business consultant in New York for the past 16 years.
            WPT Investing Corp is a fully incorporated entity in the state of
            New York. We are in the process of applying for a Money Transmitter
            License. Also, our ecosystem has an anti-whale mechanism and an
            anti-bot system.
          </Box>
          <Box mt={lg ? "calc(100vw / 1440 * 24)" : "24px"}>
            {sharings.map((data, i) => {
              return (
                <Item key={i}>
                  <Box mr={"10px"} lineHeight={0}>
                    <GiCheckMark />
                  </Box>
                  <Box>{data}</Box>
                </Item>
              );
            })}
          </Box>
        </Box>
        <Vector1 />
        <Vector2 />
        <Vector2 top={lg ? "calc(100vw / 1440 * 392)" : "392px"} />
      </StyledContainer>
    </Element>
  );
}

const Vector1 = styled(Box)`
  position: absolute;
  right: calc(100vw / 1440 * 0);
  top: calc(100vw / 1440 * 411);
  background-image: url("/images/sharing/vector1.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 259);
  height: calc(100vw / 1440 * 598);
  z-index: 5;
  @media screen and (max-width: 1440px) {
    top: 411px;
    width: 259px;
    height: 598px;
  }
  @media screen and (max-width: 550px) {
    width: 150px;
    height: 300px;
  }
`;

const Vector2 = styled(Box)`
  position: absolute;
  left: calc(100vw / 1440 * 0);
  top: calc(100vw / 1440 * 0);
  background-image: url("/images/sharing/vector2.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 712);
  height: calc(100vw / 1440 * 777);
  z-index: 5;
  @media screen and (max-width: 1440px) {
    width: 100%;
    height: 100%;
    max-width: 712px;
    max-height: 777px;
  }
  @media screen and (max-width: 550px) {
    width: 320px;
    height: 380px;
  }
`;

const Item = styled(Box)`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: calc(100vw / 1440 * 32);
  padding: calc(100vw / 1440 * 6) calc(100vw / 1440 * 15) calc(100vw / 1440 * 9)
    calc(100vw / 1440 * 12);
  width: fit-content;
  line-height: 175%;
  margin-bottom: calc(100vw / 1440 * 16);
  font-size: calc(100vw / 1440 * 16);
  color: #00ffff;
  @media screen and (max-width: 1440px) {
    border-radius: 32px;
    padding: 6px 15px 9px 12px;
    width: fit-content;
    line-height: 175%;
    margin-bottom: 16px;
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 157) 0 calc(100vw / 1440 * 180);
  position: relative;
  background: #2b076e;
  padding-top: calc(100vw / 1440 * 174);
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 1440px) {
    padding-left: 120px;
    padding-right: 80px;
    padding-top: 174px;
  }
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 174px;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  overflow: hidden;
`;

export default Sharing;
