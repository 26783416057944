import styled from "styled-components";
import { FiChevronDown } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";

function Dropdown({ value, setValue }) {
  const [open, setOpen] = useState(false);
  const dialog = useRef();
  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (dialog && dialog.current && !dialog.current.contains(event.target)) {
        setOpen(false);
      }
    });
  }, []);

  const menus = {
    wpt: {
      icon: "/icons/wpt.png",
      text: "WPT",
    },
    bacon: {
      icon: "/icons/bacon.png",
      text: "BACON",
    },
  };
  return (
    <StyledContainer onClick={() => setOpen(!open)} ref={dialog} open={open}>
      <div className="mr-4 leading-3">Choose Token</div>
      <FiChevronDown className="absolute lg:right-2 right-1 h-full lg:text-xl text-base" />
      <DropdownBody open={open}>
        {Object.keys(menus).map((key, i) => {
          return (
            <div
              key={i}
              className="flex justify-center items-center transition hover:bg-[#62ffaa] lg:h-[42px] h-[38px]"
              onClick={() => setValue(menus[key].text.toLowerCase())}
            >
              <img src={menus[key].icon} alt={""} className="lg:w-5 w-3.5 mr-2" />
              <div>{menus[key].text}</div>
            </div>
          );
        })}
      </DropdownBody>
    </StyledContainer>
  );
}

export default Dropdown;

const DropdownBody = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  height: ${({ open }) => (open ? "84px" : 0)};
  width: 100%;
  overflow: hidden;
  transition: all 0.15s;
  z-index: 1000;
  background: linear-gradient(315deg, white 30%, #62ffaa 100%);
  box-shadow: 0px 0px 10px #62ffaa;
  border-bottom-left-radius: ${({ open }) => (open ? "8px" : 0)};
  border-bottom-right-radius: ${({ open }) => (open ? "8px" : 0)};
  @media screen and (max-width: 1140px) {
    height: ${({ open }) => (open ? "76px" : 0)};
  }
`;

const StyledContainer = styled.div`
  position: relative;
  width: 140px;
  height: 42px;
  border-radius: 8px;
  background: linear-gradient(150deg, #62ffaa 30%, white 100%);
  box-shadow: 0px 0px 10px #62ffaa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  font-weight: bold;
  color: #000c27 !important;
  z-index: 1000;
  border-bottom-left-radius: ${({ open }) => (!open ? "8px" : 0)};
  border-bottom-right-radius: ${({ open }) => (!open ? "8px" : 0)};
  transition: all 0.15s;
  @media screen and (max-width: 1140px) {
    font-size: 12px;
    height: 38px;
    width: 130px;
  }
`;
