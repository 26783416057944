import React, { useState } from "react";

const defaultVal = {
  curToken: "wpt",
  setCurToken: () => {},
};

export const ProjectInfoContext = React.createContext(defaultVal);

export default function useProjectInfo() {
  return React.useContext(ProjectInfoContext);
}

export function ProjectInfoProvider({ children }) {
  const [curToken, setCurToken] = useState("wpt");

  return (
    <ProjectInfoContext.Provider
      value={{
        curToken,
        setCurToken,
      }}
      children={children}
    />
  );
}
