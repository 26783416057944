/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import "./menu.css";
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { scroller } from "react-scroll";
import Dropdown from "./Dropdown";
import ConnectMenu from "./ConnectMenu";
import useProjectInfo from "../../hooks/useProjectInfo";

const Hamburger = ({ curpage, setCurPage }) => {
  const [toggle, setToggle] = useState(false);
  const { curToken, setCurToken } = useProjectInfo();
  const menuRef = useRef(null);
  const md = useMediaQuery("(max-width : 960px)");
  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        let form = document.getElementById("check");
        if (form) form.checked = false;
      }
    });
  }, []);

  const texts = ["Overview", "About", "Ecosystem", "Roadmap", "Dashboard", "Audit"];

  const socials = [
    {
      url: "/images/footer/telegram.png",
      link: "https://t.me/WPTInvestingCorp",
    },
    {
      url: "/images/footer/twitter.png",
      link: "https://twitter.com/WPT_Investing",
    },
    {
      url: "/images/footer/mail.png",
      link: "mailto:Support@wptinvestingcorp.io",
    },
    {
      url: "/images/footer/github.png",
      link: "https://github.com/Wptceo",
    },
    {
      url: "/images/footer/youtube.png",
      link: "https://youtube.com/@wptinvestingcorp",
    },
    // {
    //   url: "/images/footer/reddit.png",
    //   link: "https://www.tiktok.com/@wptinvestingcorp",
    // },
  ];

  const lg = useMediaQuery("(min-width : 1440px)");
  const xs = useMediaQuery("(max-width : 400px)");
  return (
    <nav role="navigation" className="z-[1000]">
      <div id="menuToggle" ref={menuRef}>
        {/* A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it. */}
        {/* Some spans to act as a hamburger.
    
    They are acting like a real hamburger,
    not that McDonalds stuff. */}
        {toggle ? (
          <AiOutlineClose className="w-6 h-6 cursor-pointer" onClick={() => setToggle(false)} />
        ) : (
          <MenuItem onClick={() => setToggle(true)}>MENU</MenuItem>
        )}
        {/* Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic. */}
      </div>
      <Menu id="menu" open={toggle}>
        <LineVector />
        <Vector />
        <Box
          display={"flex"}
          minWidth={lg ? "calc(100vw / 1440 * 291)" : md ? "200px" : "291px"}
          minHeight={lg ? "calc(100vw / 1440 * 152)" : md ? "104px" : "152px"}
          maxWidth={lg ? "calc(100vw / 1440 * 291)" : md ? "200px" : "291px"}
          maxHeight={lg ? "calc(100vw / 1440 * 152)" : md ? "104px" : "152px"}
        >
          <img src={"/logotext.png"} width={"100%"} height={"100%"} alt={""} />
        </Box>
        <MenuPanel>
          <Panel>
            <TextPanel>
              {texts.map((data, i) => {
                return i === 4 ? (
                  <a href={"https://wptdapp.io/"} target={"_blank"} rel="noreferrer" key={i}>
                    <TextItem active={(curpage === i).toString()} key={i}>
                      {data}
                    </TextItem>{" "}
                  </a>
                ) : i === 5 ? (
                  <a href={"/docs/Brewlabs Factory - WPT Token Audit.pdf"} target={"_blank"} rel="noreferrer" key={i}>
                    <TextItem active={(curpage === i).toString()} key={i}>
                      {data}
                    </TextItem>{" "}
                  </a>
                ) : (
                  <TextItem
                    onClick={() => {
                      setToggle(false);
                      scroller.scrollTo(data, {
                        duration: 1000,
                        delay: 0,
                        smooth: true,
                      });
                      let form = document.getElementById("check");
                      if (form) form.checked = false;
                    }}
                    active={(curpage === i).toString()}
                    key={i}
                  >
                    {data}
                  </TextItem>
                );
              })}
            </TextPanel>
          </Panel>
        </MenuPanel>
        <div className="flex ml-8 mt-8">
          <div>
            <Dropdown value={curToken} setValue={setCurToken}></Dropdown>
          </div>
          <div className="ml-4">
            <ConnectMenu>Connect Wallet</ConnectMenu>
          </div>
        </div>
        <SocialPanel>
          {socials.map((data, i) => {
            return (
              <a href={data.link} target={"_blank"} rel="noreferrer" key={i}>
                <SocialItem
                  href={data.link}
                  target={"_blank"}
                  minWidth={
                    i > 1
                      ? lg
                        ? "calc(100vw / 1440 * 31)"
                        : xs
                        ? "25px"
                        : "31px"
                      : lg
                      ? "calc(100vw / 1440 * 37)"
                      : xs
                      ? "28px"
                      : "37px"
                  }
                  minHeight={lg ? "calc(100vw / 1440 * 31)" : xs ? "25px" : "31px"}
                  maxWidth={
                    i > 1
                      ? lg
                        ? "calc(100vw / 1440 * 31)"
                        : "25px"
                      : lg
                      ? "calc(100vw / 1440 * 37)"
                      : "28px"
                  }
                  maxHeight={lg ? "calc(100vw / 1440 * 31)" : xs ? "25px" : "31px"}
                  key={i}
                >
                  <img src={data.url} width={"100%"} height={"100%"} alt={""} />
                </SocialItem>
              </a>
            );
          })}
        </SocialPanel>
      </Menu>
    </nav>
  );
};

const MenuItem = styled(Box)`
  background: linear-gradient(150deg, #62ffaa 30%, white 100%);
  box-shadow: 0px 0px 10px #62ffaa;
  color: #000c27;
  width: 80px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold; ;
`;

const SocialItem = styled(Box)`
  display: flex;
  margin-right: calc(100vw / 1440 * 32);
  transition: all 0.3s;
  opacity: 0.7;
  transform: scale(0.8, 0.8);
  :hover {
    transform: scale(0.9, 0.9);
    opacity: 1;
  }
  @media screen and (max-width: 1440px) {
    margin-right: 32px;
  }
  @media screen and (max-width: 400px) {
    transform: scale(0.9, 0.9);
    :hover {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
`;

const SocialPanel = styled(Box)`
  position: absolute;
  bottom: 50px;
  left: 40px;
  width: calc(100vw - 40px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  @media screen and (min-width: 1440px) {
    left: calc(100vw / 1440 * 40);
    width: calc(100vw - 100vw / 1440 * 40);
    bottom: calc(100vw / 1440 * 50);
  }
`;

const Menu = styled.ul`
  font-weight: 500;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  padding: 0;

  background-color: rgba(6, 36, 60, 1);
  color: white;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;

  transform-origin: 0% 0%;
  transform: ${({ open }) => (open ? "none" : "translate(100%, 0)")};

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  font-family: "Rubik";
  padding: calc(100vw / 1440 * 27) calc(100vw / 1440 * 58) 0 calc(100vw / 1440 * 110) !important;

  > h2 {
    transition: margin 150ms ease-in;
    cursor: pointer;
    padding: 0px 0;
    font-size: 22px;
    :hover {
      color: #ff6b6b;
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 1440px) {
    padding: 27px 58px 0 110px !important;
  }
  @media screen and (max-width: 1100px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`;

const LineVector = styled(Box)`
  background: linear-gradient(to left, #d9ed92, #168aad);
  position: absolute;
  height: 5px;
  top: 200px;
  left: 0;
  width: 100%;
  @media screen and (min-width: 1440px) {
    height: calc(100vw / 1440 * 5);
    top: calc(100vw / 1440 * 200);
  }
  @media screen and (max-width: 960px) {
    top: 150px;
  }
`;

const TextItem = styled(Box)`
  padding: 15px 0px 14px 28px;
  margin-top: 10px;
  transition: all 0.3s;

  color: white;
  text-decoration: none;
  height: 48px;
  background: ${({ active }) =>
    active === "true"
      ? "linear-gradient(to right, rgba(72,202,228,0.4), rgba(0,0,0,0))!important"
      : "transparent"};
  :hover {
    background: linear-gradient(to right, rgba(72, 202, 228, 0.1), rgba(0, 0, 0, 0));
  }
  cursor: pointer;

  @media screen and (min-width: 1440px) {
    height: calc(100vw / 1440 * 48);
    padding: calc(100vw / 1440 * 15) 0px calc(100vw / 1440 * 14) calc(100vw / 1440 * 28);
    font-size: calc(100vw / 1440 * 16);
  }
`;

const TextPanel = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Panel = styled(Box)`
  padding-top: 34px;
  height: fit-content;
  width: 100%;
  background: transparent;
  z-index: 10;
  @media screen and (min-width: 1440px) {
    padding-top: calc(100vw / 1440 * 34);
  }
`;

const MenuPanel = styled(Box)`
  display: flex;
  height: fit-content;
  overflow-y: hidden;
`;

const Vector = styled(Box)`
  position: absolute;
  background: url("/images/vector.png");
  background-size: 100% 100%;
  width: 305px;
  height: 484px;
  right: 14px;
  bottom: -49px;
  opacity: 0.07;
`;
export default Hamburger;
