/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import { Element } from "react-scroll";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";

function Roadmap() {
  const roadmaps = [
    {
      title: "Phase One",
      detail: [
        { text: "200+ Holders", checked: true },
        { text: "Website Launch", checked: true },
        { text: "Social Media Launch", checked: true },
        { text: "WPT Investing Corporation (New York State)", checked: true },
        { text: "Lock Liquidity Pool (1 year)", checked: true },
        { text: "Token Contracts", checked: true },
        { text: "Launch Token (July 7, 2022)", checked: true },
        { text: "Staking Platform", checked: true },
        { text: "Yield Farming Platform", checked: true },
        { text: "List on CoinMarketCap, CoinGecko", checked: true },
      ],
      xs: "196px",
      md: "196px",
    },
    {
      title: "Phase Two",
      detail: [
        { text: "400+ holders ", checked: true },
        { text: "Begin Marketing Campaign", checked: true },
        { text: "Decentralized Application (dApp) Launch ", checked: true },
        { text: "$Bacon Token Contract ", checked: true },
        {
          text: `NFT Creation (2D/3D "War Pigs Beasties" Collections)`,
          checked: true,
        },
        { text: "CertiK Audits - Brewlabs Smart Contracts", checked: true },
        { text: "Apply Tier 3 Exchanges ", checked: false },
      ],
      xs: "650px",
      md: "472px",
    },
    {
      title: "Phase Three",
      detail: [
        { text: "850+ holders ", checked: false },
        { text: "Website 3.0 Upgrade ", checked: true },
        { text: "NFT Collections Staking Contracts", checked: true },
        { text: "NFT Staking dApp ", checked: true },
        {
          text: `NFT Minting Engine dApp`,
          checked: true,
        },
        {
          text: "2D Epic Collection Stakeable NFTs (Qty: 1000)",
          checked: true,
        },
        {
          text: "3D Legendary Collection Stakeable NFTs (Qty: 1000)",
          checked: true,
        },
        {
          text: "3D Founders Collection Stakeable NFTs (Qty: 1000)",
          checked: true,
        },
        {
          text: "Apply Tier 2 Exchanges",
          checked: false,
        },
      ],
      xs: "1105px",
      md: "740px",
    },
    {
      title: "Phase Four",
      detail: [
        { text: "1200+ holders ", checked: false },
        { text: "$Bacon Token Public Launch ", checked: true },
        { text: "Money Transmitter License ", checked: false },
        {
          text: "Virtual Currency Business Activity License (NY)",
          checked: false,
        },
        {
          text: `BitLicense (NY) `,
          checked: false,
        },
        {
          text: "The Syndicate Formalized & Announced",
          checked: true,
        },
        {
          text: "Launch NFT Syndication Faucet",
          checked: false,
        },
      ],
      xs: "1557px",
      md: "1015px",
    },
    {
      title: "Phase Five",
      detail: [
        { text: "2500+ holders ", checked: false },
        {
          text: "$BLD Token Contract (Business Loan Decentralized)",
          checked: false,
        },
        { text: "iOS/Android App ($BLD) ", checked: false },
        {
          text: "$Ham Token Contract",
          checked: false,
        },
        {
          text: `$DollarX Stablecoin Contract `,
          checked: false,
        },
        {
          text: "Apply Tier 1 Exchanges",
          checked: false,
        },
      ],
      xs: "1965px",
      md: "1290px",
    },
    {
      title: "Phase Six",
      detail: [
        { text: "5000+ holders ", checked: false },
        { text: "P2E Game (First Person Shooter)", checked: false },
        { text: "Metaverse Lands and Worlds", checked: false },
        { text: "3D NFT Collection (Cronos & Polygon Chains)", checked: false },
      ],
      xs: "2325px",
      md: "1565px",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 1000px)");
  const sm = useMediaQuery("(max-width : 720px)");
  const xs = useMediaQuery("(max-width : 620px)");
  const xxs = useMediaQuery("(max-width : 500px)");
  return (
    <Element name={"Roadmap"}>
      <StyledContainer>
        <Box
          fontSize={lg ? "calc(100vw / 1440 * 40)" : "40px"}
          fontWeight={"bold"}
          textAlign={"center"}
        >
          Roadmap
        </Box>
        <ArrowPanel>
          {roadmaps.map((data, i) => {
            if (!md && i > 2) return "";
            return (
              <ArrowItem
                display={"flex"}
                minWidth={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                maxWidth={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                minHeight={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                maxHeight={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                position={"relative"}
                zIndex={10}
                left={(sm ? false : i % 2 === 0).toString()}
                key={i}
              >
                <img src={"/images/roadmap/arrow.png"} alt={""} width={"100%"} height={"100%"} />
              </ArrowItem>
            );
          })}
          <ArrowLine />
        </ArrowPanel>
        <Box
          mt={lg ? "calc(100vw / 1440 * 11)" : "11px"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {roadmaps.map((data, i) => {
            if (!md && i > 2) return "";
            return (
              <Item
                data-aos="fade-up"
                data-aos-delay={300 * i}
                top={md ? (sm ? data.xs : data.md) : "unset"}
                key={i}
                left={
                  sm
                    ? xs
                      ? xxs
                        ? "80px"
                        : "140px"
                      : "200px"
                    : md
                    ? i % 2 === 0
                      ? "calc(50% - 340px)"
                      : "calc(50% + 40px)"
                    : "unset"
                }
              >
                <Box color={"#00FFFF"} textAlign={"center"} fontWeight={"bold"}>
                  {data.title}
                </Box>
                <Box mt={lg ? "calc(100vw / 1440 * 6)" : "6px"}>
                  {data.detail.map((data, i) => {
                    return (
                      <Detail key={i}>
                        <Box display={"flex"} color={"white"} alignItems={"center"}>
                          <Box minWidth={lg ? "calc(100vw / 1440 * 27)" : "25px"} color={"#00FFFF"}>
                            {data.checked ? <GiCheckMark /> : ""}
                          </Box>

                          <Box>{data.text}</Box>
                        </Box>
                      </Detail>
                    );
                  })}
                </Box>
              </Item>
            );
          })}
        </Box>
        {!md ? (
          <ArrowPanel data-aos="fade-up" data-aos-delay={300} mt={"50px"}>
            {roadmaps.map((data, i) => {
              if (i < 3) return "";
              return (
                <ArrowItem
                  display={"flex"}
                  minWidth={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                  maxWidth={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                  minHeight={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                  maxHeight={lg ? "calc(100vw / 1440 * 32)" : "32px"}
                  position={"relative"}
                  zIndex={10}
                  left={sm ? false : !i % 2}
                  key={i}
                >
                  <img src={"/images/roadmap/arrow.png"} alt={""} width={"100%"} height={"100%"} />
                </ArrowItem>
              );
            })}
            <ArrowLine />
          </ArrowPanel>
        ) : (
          ""
        )}
        {!md ? (
          <Box
            mt={lg ? "calc(100vw / 1440 * 11)" : "11px"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            {roadmaps.map((data, i) => {
              if (i < 3) return "";
              return (
                <Item
                  data-aos="fade-up"
                  data-aos-delay={300 * (i - 2)}
                  top={md ? (sm ? data.xs : data.md) : "unset"}
                  key={i}
                  left={
                    sm
                      ? xs
                        ? xxs
                          ? "100px"
                          : "140px"
                        : "200px"
                      : md
                      ? i % 2 === 0
                        ? "calc(50% - 340px)"
                        : "calc(50% + 40px)"
                      : "unset"
                  }
                >
                  <Box color={"#00FFFF"} textAlign={"center"} fontWeight={"bold"}>
                    {data.title}
                  </Box>
                  <Box mt={lg ? "calc(100vw / 1440 * 6)" : "6px"}>
                    {data.detail.map((data, i) => {
                      return (
                        <Detail key={i}>
                          <Box display={"flex"} color={"white"} alignItems={"center"}>
                            <Box
                              minWidth={lg ? "calc(100vw / 1440 * 27)" : "25px"}
                              color={"#00FFFF"}
                            >
                              {data.checked ? <GiCheckMark /> : ""}
                            </Box>

                            <Box>{data.text}</Box>
                          </Box>
                        </Detail>
                      );
                    })}
                  </Box>
                </Item>
              );
            })}
          </Box>
        ) : (
          ""
        )}
      </StyledContainer>
    </Element>
  );
}

const Detail = styled(Box)`
  > div {
    margin-top: 15px;
    line-height: 120%;
  }
`;

const ArrowItem = styled(Box)`
  @media screen and (max-width: 1000px) {
    transform: rotate(${({ left }) => (left === "true" ? "90deg" : "270deg")});
  }
`;

const ArrowPanel = styled(Box)`
  margin-top: calc(100vw / 1440 * 15);
  margin-left: calc(100vw / 1440 * 102);
  margin-right: calc(100vw / 1440 * 102);
  display: flex;
  position: relative;
  justify-content: space-between;
  @media screen and (max-width: 1440px) {
    margin: 15px 102px 0 102px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    > div {
      margin-bottom: 240px;
    }
    > div:nth-child(6) {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 720px) {
    > div {
      margin-bottom: 420px;
    }
    > div:nth-child(4) {
      margin-bottom: 380px;
    }
    > div:nth-child(5) {
      margin-bottom: 330px;
    }
    align-items: unset;
  }
  @media screen and (max-width: 620px) {
    margin: 50px 50px 0 50px;
  }
  @media screen and (max-width: 500px) {
    margin: 50px 20px 0 20px;
  }
`;

const ArrowLine = styled(Box)`
  position: absolute;
  top: calc(100vw / 1440 * 16);
  left: 0;
  height: calc(100vw / 1440 * 2);
  width: 100%;
  background-color: #00ffff;
  @media screen and (max-width: 1440px) {
    top: 16px;
    height: 2px;
  }
  @media screen and (max-width: 1000px) {
    top: 0;
    left: calc(50%);
    height: 100%;
    width: 2px;
  }
  @media screen and (max-width: 720px) {
    left: 16px;
  }
`;

const Item = styled(Box)`
  background: rgba(0, 255, 255, 0.3);
  border-radius: calc(100vw / 1440 * 8);
  padding: calc(100vw / 1440 * 10) calc(100vw / 1440 * 25) calc(100vw / 1440 * 30)
    calc(100vw / 1440 * 25);
  line-height: 175%;
  font-size: calc(100vw / 1440 * 16);
  color: black;
  width: calc(100vw / 1440 * 300);
  @media screen and (max-width: 1440px) {
    border-radius: 8px;
    padding: 10px 20px 30px 20px;
    font-size: 14px;
    width: 300px;
  }
  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 0;
  }
  @media screen and (max-width: 420px) {
    width: 270px;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 214) 0 calc(100vw / 1440 * 197);
  background: #2b076e;
  padding-top: calc(100vw / 1440 * 76);
  position: relative;
  @media screen and (max-width: 1440px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 76px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 20px;
  }
  @media screen and (max-width: 1000px) {
    padding-bottom: 300px;
  }
  overflow: hidden;
`;

export default Roadmap;
