/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { scroller } from "react-scroll";
import Hamburger from "./Hamburger";
import ConnectMenu from "./ConnectMenu";
import Dropdown from "./Dropdown";
import useProjectInfo from "../../hooks/useProjectInfo";

function TopBar() {
  const { curToken, setCurToken } = useProjectInfo();
  const links = ["Overview", "About", "Ecosystem", "Roadmap", "Dashboard", "Audit"];
  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 1060px)");
  const hm = useMediaQuery("(max-width : 960px)");

  return (
    <StyledContainer>
      <Box
        display={"flex"}
        minWidth={lg ? "calc(100vw / 1440 * 291)" : md ? "200px" : "291px"}
        minHeight={lg ? "calc(100vw / 1440 * 152)" : md ? "104px" : "152px"}
        maxWidth={lg ? "calc(100vw / 1440 * 291)" : md ? "200px" : "291px"}
        maxHeight={lg ? "calc(100vw / 1440 * 152)" : md ? "104px" : "152px"}
      >
        <img src={"/logotext.png"} width={"100%"} height={"100%"} alt={""} />
      </Box>
      <Menus>
        {links.map((data, i) => {
          return i === 4 ? (
            <a href={"https://wptdapp.io"} target={"_blank"} rel="noreferrer" key={i}>
              <Box>{data}</Box>{" "}
            </a>
          ) : i === 5 ? (
            <a href={"/docs/Brewlabs Factory - WPT Token Audit.pdf"} target={"_blank"} rel="noreferrer" key={i}>
              <Box>{data}</Box>{" "}
            </a>
          ) :  (
            <Box
              onClick={() =>
                scroller.scrollTo(data, {
                  duration: 1000,
                  delay: 0,
                  smooth: true,
                })
              }
              key={i}
            >
              {data}
            </Box>
          );
        })}
        <Dropdown value={curToken} setValue={setCurToken}></Dropdown>
        <ConnectMenu>Connect Wallet</ConnectMenu>
      </Menus>
      {hm ? <Hamburger /> : ""}
    </StyledContainer>
  );
}

const Menus = styled(Box)`
  display: flex;
  align-items: center;
  height: fit-content;
  margin-top: calc(100vw / 1440 * 45);
  > div {
    font-size: calc(100vw / 1440 * 16);
    line-height: 175%;
    margin-right: calc(100vw / 1440 * 18);
    cursor: pointer;
    transition: all 0.3s;
    :hover {
      color: #3671e9;
    }
  }
  > a > div {
    font-size: calc(100vw / 1440 * 16);
    line-height: 175%;
    margin-right: calc(100vw / 1440 * 18);
    cursor: pointer;
    transition: all 0.3s;
    :hover {
      color: #3671e9;
    }
  }
  > div:last-child {
    margin: 0;
  }
  @media screen and (max-width: 1440px) {
    margin-top: 45px;
    > div {
      font-size: 16px;
      margin-right: 18px;
    }
    > a > div {
      font-size: 16px;
      margin-right: 18px;
    }
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const StyledContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  color: white;
  padding: calc(100vw / 1440 * 27) calc(100vw / 1440 * 58) 0 calc(100vw / 1440 * 110);
  z-index: 1000;
  @media screen and (max-width: 1440px) {
    padding: 27px 58px 0 110px;
  }
  @media screen and (max-width: 1250px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export default TopBar;
