/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import { Element } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function About({ data }) {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 960px)");
  const sm = useMediaQuery("(max-width : 500px)");

  return (
    <StyledContainer>
      <Element name={"About"}>
        <Box
          mt={lg ? "calc(100vw / 1440 * 100)" : md ? "80px" : "100px"}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={md ? "column" : "row"}
          width={"100%"}
          alignItems={md ? "center" : "unset"}
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <Box
            display={"flex"}
            minWidth={lg ? "calc(100vw / 1440 * 650)" : sm ? "350px" : "530px"}
            minHeight={lg ? "calc(100vw / 1440 * 473)" : sm ? "230px" : "383px"}
            maxWidth={lg ? "calc(100vw / 1440 * 650)" : sm ? "350px" : "530px"}
            maxHeight={lg ? "calc(100vw / 1440 * 473)" : sm ? "230px" : "383px"}
          >
            <img src={"/images/about/Illustrations.svg"} width={"100%"} height={"100%"} alt={""} />
          </Box>
          <Box mt={md ? "50px" : "0"}>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "28px" : "32px"}
              lineHeight={"150%"}
              fontWeight={"bold"}
              maxWidth={lg ? "calc(100vw / 1440 * 480)" : md ? "600px" : "480px"}
            >
              About WPT Investing Corporation.
            </Box>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
              lineHeight={"175%"}
              color={"#E0E0E0"}
              maxWidth={lg ? "calc(100vw / 1440 * 400)" : md ? "600px" : "400px"}
            >
              WPT is an innovative token on the Ethereum Blockchain. We are the first token to
              partner with the corporate world and blockchain through our Decentralized WebApp,
              starting with prime Merchant Cash Advance (MCA) lenders. By syndicating our corporate
              treasury tax-earned funds to MCAs, WPT Investing Corp will bring 10% to 50% of ROI
              into our ecosystem. Every time this happens, there will be significant buybacks and
              USDC rewards and our token stakers will receive consistent and considerable passive
              income.
            </Box>
            <Box mt={lg ? "calc(100vw / 1440 * 25)" : "25px"}>
              <a
                href={
                  "/docs/WPT May 2024 WP.pdf"
                }
                target={"_blank"}
                rel="noreferrer"
              >
                <Button
                  type={"arrow1"}
                  width={lg ? "calc(100vw / 1440 * 238)" : sm ? "195px" : "238px"}
                  height={lg ? "calc(100vw / 1440 * 61)" : sm ? "50px" : "61px"}
                  fontSize={lg ? "calc(100vw / 1440 * 18)" : sm ? "14px" : "18px"}
                >
                  Visit Whitepaper
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        <Vector1 />
        <Vector2 />
      </Element>
    </StyledContainer>
  );
}

const Vector1 = styled(Box)`
  background-image: url("/images/about/vector1.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 185);
  height: calc(100vw / 1440 * 196);
  position: absolute;
  right: calc(100vw / 1440 * 0);
  top: calc(100vw / 1440 * 183);
  @media screen and (max-width: 1440px) {
    width: 185px;
    height: 196px;
    right: 0px;
    top: 183px;
  }
`;

const Vector2 = styled(Box)`
  background-image: url("/images/about/vector2.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 154);
  height: calc(100vw / 1440 * 120);
  position: absolute;
  right: 0;
  top: calc(100vw / 1440 * 640);
  @media screen and (max-width: 1440px) {
    width: 154px;
    height: 120px;
    top: 640px;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 120);
  padding-top: calc(100vw / 1440 * 100);
  position: relative;
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 100px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  overflow: hidden;
`;

export default About;
