/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import styled from "styled-components";

function ConnectMenu({ setNotification }) {
  const { connect, disconnect, connected, web3 } = useWeb3Context();
  const address = useAddress();
  const [isConnected, setConnected] = useState(connected);

  const sm = useMediaQuery("(max-width : 1140px)");

  let ellipsis = address
    ? sm
      ? "Connected"
      : (
          address.slice(0, 7) +
          "..." +
          address.substring(address.length - 5, address.length - 1)
        ).toUpperCase()
    : sm
    ? "Connect"
    : "Connect Wallet";

  let buttonText = ellipsis;

  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  if (isConnected) {
    buttonText = ellipsis;
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <div>
      <ConnectButton
        active={isConnected.toString()}
        onClick={() => (isConnected ? disconnect() : onConnect())}
      >
        <Box>{buttonText}</Box>
        <img
          src={isConnected ? "/icons/connected.png" : "/icons/connect.png"}
          width={sm ? "15px" : "17px"}
        />
      </ConnectButton>
    </div>
  );
}

const ConnectButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 42px;
  background: ${({ active }) => (active === "true" ? "#62FFAA" : "#FF626E")};
  border: 1px solid ${({ active }) => (active === "true" ? "#62FFAA" : "#FD4E4E")};
  cursor: pointer;
  box-shadow: 0px 0px 20px ${({ active }) => (active === "true" ? "#62FFAA" : "#FD3443")};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 0px;
  color: #000c27;
  :hover {
    animation: pulse 1s;
    box-shadow: 0 0 0 2.5em rgba(255, 255, 255, 0);
  }
  transition: all 0.2s;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${({ active }) => (active === "true" ? "#62FFAA" : "#FD4E4E")};
    }
  }
  > div {
    margin-right: 18px;
  }
  @media screen and (max-width: 1140px) {
    width: 116px;
    height: 38px;
    font-size: 12px;
    font-weight: 500;
    > div {
      margin-right: 12px;
    }
  }
`;

export default ConnectMenu;
