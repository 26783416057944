/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Balance from "../../components/Balance";
import useProjectInfo from "../../hooks/useProjectInfo";
import { TOKEN_ADDRS } from "../../abis/address";

function Treasury({ fees, treasuryETHBalance, treasuryBalance }) {
  const { curToken } = useProjectInfo();

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  const treasuries = [
    {
      url: "/images/treasury/ethereum.png",
      children: <Balance prefix="$" value={treasuryBalance} />,
      title: "WPT Rewards Treasury",
      detail:
        "The WPT Investing Corp Rewards Treasury (Porkchop) executes constant buy-backs of WPT’s ecosystem tokens. While taking these tokens out of circulation and raising floor price, these can then be used to top up our Brewlabs / CertiK Audited Staking Pools and Farms to reward holders and raise the bar for the WPT Investing Corp ecosystem.",
      buttontext: "Visit Contract",
    },
    {
      url: "/images/treasury/ethereum.png",
      children: <Balance suffix="ETH" value={treasuryETHBalance} />,
      title: "WPT Corporate Treasury",
      detail:
        "The WPT Taxes earned in ETH by the Corporate Treasury will allow WPT to increase its syndication on a monthly basis.",
      buttontext: "Visit Wallet",
    },
  ];

  const lg = useMediaQuery("(min-width : 1440px)");
  const md = useMediaQuery("(max-width : 960px)");
  const sm = useMediaQuery("(max-width : 500px)");

  return (
    <StyledContainer>
      <Box
        mt={lg ? "calc(100vw / 1440 * 100)" : "100px"}
        textAlign={"center"}
        fontSize={lg ? "calc(100vw / 1440 * 40)" : sm ? "24px" : "40px"}
        lineHeight={"150%"}
        fontWeight={"bold"}
        maxWidth={lg ? "calc(100vw / 1440 * 740)" : "740px"}
        mx={"auto"}
        data-aos="fade-up"
        data-aos-delay={300}
      >
        WPT Investing Corporation groundbreaking dual treasury model.
      </Box>
      <Panel
        mt={lg ? "calc(100vw / 1440 * 114)" : md ? (sm ? "30px" : "50px") : "114px"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={md ? "column" : "row"}
        alignItems={md ? "center" : "unset"}
      >
        {treasuries.map((data, i) => {
          return (
            <Item key={i} data-aos="fade-up" data-aos-delay={300 * i}>
              <Box
                display={"flex"}
                minWidth={lg ? "calc(100vw / 1440 * 80)" : sm ? "50px" : "80px"}
                minHeight={lg ? "calc(100vw / 1440 * 80)" : sm ? "50px" : "80px"}
                maxHeight={lg ? "calc(100vw / 1440 * 80)" : sm ? "50px" : "80px"}
                maxWidth={lg ? "calc(100vw / 1440 * 80)" : sm ? "50px" : "80px"}
              >
                <img
                  src={"/images/treasury/ethereum.png"}
                  width={"100%"}
                  height={"100%"}
                  alt={""}
                />
              </Box>
              <Box
                mt={lg ? "calc(100vw / 1440 * 16)" : "16px"}
                fontWeight={"bold"}
                color={"black"}
                fontSize={lg ? "calc(100vw / 1440 * 40)" : sm ? "24px" : "40px"}
              >
                {data.children}
              </Box>
              <Box
                fontWeight={"bold"}
                fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "24px" : "32px"}
                mt={lg ? "calc(100vw / 1440 * 9)" : "9px"}
              >
                {data.title}{" "}
                <span
                  style={{
                    fontSize: lg ? "calc(100vw / 1440 * 18)" : sm ? "16px" : "18px",
                    fontWeight: 500,
                    color: "#BDBDBD",
                  }}
                >
                  ETH
                </span>
              </Box>
              <Box
                mt={lg ? "calc(100vw / 1440 * 11)" : "11px"}
                fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
                textAlign={"center"}
                color={"#828282"}
                lineHeight={"175%"}
                maxWidth={lg ? "calc(100vw / 1440 * 330)" : "330px"}
              >
                {data.detail}
                {i === 1 && !md ? (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </Box>
              <Box mt={lg ? "calc(100vw / 1440 * 20)" : "20px"}>
                <a
                  href={"https://etherscan.io/address/0x547ed2ed1c7b19777dc67cae4a23d00780a26c36"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Button
                    type={"arrow2"}
                    width={
                      i === 0
                        ? lg
                          ? "calc(100vw / 1440 * 217)"
                          : sm
                          ? "180px"
                          : "217px"
                        : lg
                        ? "calc(100vw / 1440 * 191)"
                        : sm
                        ? "162px"
                        : "191px"
                    }
                    height={lg ? "calc(100vw / 1440 * 60)" : sm ? "48px" : "60px"}
                    fontSize={lg ? "calc(100vw / 1440 * 18)" : sm ? "14px" : "18px"}
                  >
                    {data.buttontext}
                  </Button>
                </a>
              </Box>
            </Item>
          );
        })}
      </Panel>
      <TaxPanel className={"flex items-center justify-between"} data-aos="flip-down">
        <Box>
          <Box className={"font-medium"}>
            <div className="text-[#0D0D2B]">{curToken.toUpperCase()} TOKEN</div>
            <div className="text-[#3671E9] uppercase mt-1.5">Dynamic Token Tax Structure</div>
          </Box>
          <Box color={"#828282"} lineHeight={"175%"} className={"mb-3.5 tracking-wide"}>
            {curToken.toUpperCase()} Token taxes offer holder rewards and boost treasury ROI
            foundation.
          </Box>
          <a
            href={`https://etherscan.io/address/${TOKEN_ADDRS[curToken]}#readContract`}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button
              type={"arrow2"}
              width={lg ? "calc(100vw / 1440 * 192)" : "192px"}
              height={lg ? "calc(100vw / 1440 * 34)" : "34px"}
              fontSize={lg ? "calc(100vw / 1440 * 18)" : "18px"}
              ellipsisSize={"22"}
            >
              Read Contract
            </Button>
          </a>
        </Box>
        <Box>
          <StyledTax className={"flex items-center justify-between"}>
            <Box className={"font-medium"}>Buy Tax</Box>
            <TaxPercent className={"flex items-center justify-center"}>
              {fees[curToken].buyFee < 7 ? <img src={"/icons/bell.svg"} alt={""} /> : ""}
              <Box className={"font-bold ml-2.5"} color={"#0D0D2B"}>
                {fees[curToken].buyFee.toFixed(2)}%
              </Box>
            </TaxPercent>
          </StyledTax>
          <Box className={"mt-4"} />
          <StyledTax className={"flex items-center justify-between"}>
            <Box className={"font-medium"}>Sell Tax</Box>
            <TaxPercent className={"flex items-center justify-center"}>
              <Box className={"font-bold"} color={"#0D0D2B"}>
                {fees[curToken].sellFee.toFixed(2)}%
              </Box>
            </TaxPercent>
          </StyledTax>
        </Box>
      </TaxPanel>
    </StyledContainer>
  );
}

const TaxPercent = styled(Box)`
  background: #ffffff;
  border-radius: 32px;
  width: 100%;
  max-width: 164px;
  @media screen and (min-width: 1440px) {
    border-radius: calc(100vw / 1440 * 32);
    max-width: calc(100vw / 1440 * 164);
  }
  @media screen and (max-width: 500px) {
    max-width: 90px;
    > img {
      transform: scale(0.8);
    }
    > div {
      margin-left: 4px !important;
    }
  }
`;

const StyledTax = styled(Box)`
  font-size: 32px;
  background: #252540;
  border-radius: 32px;
  color: white;
  padding: 6px 6px 6px 40px;
  @media screen and (min-width: 1440px) {
    font-size: calc(100vw / 1440 * 32);
    background: #252540;
    border-radius: calc(100vw / 1440 * 32);
    color: white;
    padding: calc(100vw / 1440 * 6) calc(100vw / 1440 * 6) calc(100vw / 1440 * 6)
      calc(100vw / 1440 * 40);
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
    padding-left: 16px;
  }
`;
const TaxPanel = styled(Box)`
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 80px 40px #391777;
  border-radius: 0px 0px 16px 16px;
  width: 100%;
  max-width: 942px;
  margin: 87px auto 0 auto;
  padding: 18px 70px 21px 48px;
  > div:nth-child(1) {
    width: 100%;
    max-width: 285px;
  }
  > div:nth-child(2) {
    width: 100%;
    max-width: 418px;
  }
  z-index: 10;
  position: relative;
  @media screen and (min-width: 1440px) {
    box-shadow: 0px calc(100vw / 1440 * 80) calc(100vw / 1440 * 40) #391777;
    border-radius: 0px 0px calc(100vw / 1440 * 16) calc(100vw / 1440 * 16);
    max-width: calc(100vw / 1440 * 942);
    margin: calc(100vw / 1440 * 87) auto 0 auto;
    padding: calc(100vw / 1440 * 18) calc(100vw / 1440 * 70) calc(100vw / 1440 * 21)
      calc(100vw / 1440 * 48);
    > div:nth-child(1) {
      max-width: calc(100vw / 1440 * 285);
      font-size: calc(100vw / 1440 * 16);
    }
    > div:nth-child(2) {
      max-width: calc(100vw / 1440 * 418);
    }
  }
  @media screen and (max-width: 800px) {
    margin-top: 30px;
    flex-direction: column;
    > div {
      margin-bottom: 20px;
    }
    > div:nth-child(1) {
      width: 100%;
      max-width: 418px;
    }
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 500px) {
    > div {
      max-width: 300px !important;
    }
  }
`;

const Panel = styled(Box)`
  display: flex;
  > div {
    margin-right: calc(100vw / 1440 * 40);
    width: 100%;
    max-width: calc(100vw / 1440 * 430);
  }
  > div:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1440px) {
    > div {
      margin-right: 40px;
      max-width: 430px;
    }
  }
  @media screen and (max-width: 960px) {
    > div {
      margin-right: 0;
      margin-bottom: 50px;
      max-width: 600px;
    }
  }
`;

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled(Box)`
  margin-top: -460px;
  padding: 0 calc(100vw / 1440 * 120);
  padding-top: 460px;
  position: relative;
  background: linear-gradient(180deg, #f8f9fb 0%, #fafbff 100%);
  color: #0d0d2b;
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  margin-bottom: -30px;
  @media screen and (min-width: 1440px) {
    margin-bottom: calc(-100vw / 1440 * 30);
  }
`;

export default Treasury;
