/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import Chart from "react-apexcharts";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Balance from "../../components/Balance";
import useProjectInfo from "../../hooks/useProjectInfo";

function Holders({ data, priceHistory }) {
  const { curToken } = useProjectInfo();

  const lg = useMediaQuery("(min-width : 1440px)");
  const mm = useMediaQuery("(max-width : 1250px)");
  const md = useMediaQuery("(max-width : 900px)");
  const sm = useMediaQuery("(max-width : 550px)");

  const abouts = [
    {
      url: "/images/about/chart.png",
      text: "Current Marketcap",
      value: (
        <Box>
          <Balance value={data[curToken].marketCap / 1000} decimals={0} />K
        </Box>
      ),
    },
    {
      url: "/images/about/person.png",
      text: "Certified MCA Prime Lenders",
      value: "30+",
    },
    {
      url: "/images/about/ethereum.png",
      text: "Average Syndication",
      value: "12.20 ETH",
    },
  ];

  const pricehistory = priceHistory[curToken];
  const price = pricehistory[pricehistory.length - 1] ?? 0;
  const treasuryTokenBalance = data[curToken].balance ?? 0;
  const pricechange = pricehistory.length
    ? ((pricehistory[pricehistory.length - 1] - pricehistory[0]) / pricehistory[0]) * 100
    : 0;

  const chartData = {
    series: [
      {
        name: "Price",
        data: pricehistory,
      },
    ],
    options: {
      colors: [pricechange >= 0 ? "#6EDCB5" : "#ea3943"],
      fill: {
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: true,

          stops: [0, 100],
          colorStops:
            pricechange >= 0
              ? [
                  {
                    offset: 0,
                    color: "rgb(110, 220, 181)",
                    opacity: 0.4,
                  },
                  {
                    offset: 100,
                    color: "rgb(110, 220, 181)",
                    opacity: 0,
                  },
                ]
              : [
                  {
                    offset: 0,
                    color: "#ea3943",
                    opacity: 0.4,
                  },
                  {
                    offset: 100,
                    color: "#ea3943",
                    opacity: 0,
                  },
                ],
        },
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        y: {
          format: "",
          formatter: (value) => {
            return "$" + Number(value).toFixed(2);
          },
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
    },
  };
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  function numberWithCommas(x) {
    if (!x) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <StyledContainer>
      <Box
        maxWidth={lg ? "calc(100vw / 1440 * 758)" : "758px"}
        fontWeight={"bold"}
        fontSize={lg ? "calc(100vw / 1440 * 40)" : sm ? "24px" : "40px"}
        textAlign={"center"}
        mx={"auto"}
        data-aos="fade-up"
        data-aos-delay={300}
      >
        Protecting holders with our ecosystem.
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mt={lg ? "calc(100vw / 1440 * 83)" : sm ? "30px" : "83px"}
        mr={lg ? "calc(100vw / 1440 * 66)" : "0px"}
        flexDirection={md ? "column-reverse" : "row"}
        alignItems={md ? "center" : "unset"}
        data-aos="fade-up"
        data-aos-delay={300}
      >
        <Box mt={md ? "140px" : 0}>
          <Box fontWeight={"bold"} fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "24px" : "32px"}>
            Raising the floor price.
          </Box>
          <Box
            lineHeight={"175%"}
            color={"#E0E0E0"}
            fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
            maxWidth={lg ? "calc(100vw / 1440 * 379)" : md ? "600px" : "379px"}
          >
            Each buy back that occurs as a result of real world roi increases the value of{" "}
            {curToken.toUpperCase()} Token and reduces the total tokens available for sale.
            Leveraging liquidity pool algorithms and stability. This model delivers long term{" "}
            {curToken.toUpperCase()} Token price appreciation.
          </Box>
          <Box mt={lg ? "calc(100vw / 1440 * 24)" : "24px"} width={"fit-content"}>
            <a href={"https://youtube.com/@wptinvestingcorp"} target={"_blank"} rel="noreferrer">
              <Button
                type={"connect"}
                width={lg ? "calc(100vw / 1440 * 161)" : sm ? "140px" : "161px"}
                height={lg ? "calc(100vw / 1440 * 59)" : sm ? "45px" : "59px"}
                fontSize={lg ? "calc(100vw / 1440 * 18)" : sm ? "14px" : "18px"}
              >
                Learn More
              </Button>
            </a>
          </Box>
        </Box>
        <PricePanel down={(pricechange < 0).toString()}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Box
                display={"flex"}
                minWidth={lg ? "calc(100vw / 1440 * 27)" : "27px"}
                minHeight={lg ? "calc(100vw / 1440 * 44)" : "44px"}
                maxWidth={lg ? "calc(100vw / 1440 * 64)" : "64px"}
                maxHeight={lg ? "calc(100vw / 1440 * 44)" : "44px"}
              >
                <img
                  src={`/images/price/${curToken}.png`}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                />
              </Box>
              <Box
                fontSize={lg ? "calc(100vw / 1440 * 24)" : sm ? "16px" : "24px"}
                fontWeight={"bold"}
                ml={lg ? "calc(100vw / 1440 * 7)" : "7px"}
              >
                {curToken.toUpperCase()} Token
              </Box>
              <Box
                ml={lg ? "calc(100vw / 1440 * 39)" : "39px"}
                fontSize={lg ? "calc(100vw / 1440 * 16)" : "16px"}
                style={{ opacity: 0.7 }}
                color={"#CFCFCF"}
                fontWeight={600}
              >
                ETH
              </Box>
            </Box>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"}
              color={pricechange < 0 ? "#ea3943" : "#42FFFF"}
              fontWeight={500}
            >
              {pricechange.toFixed(0)}%
            </Box>
          </Box>
          <Box
            ml={lg ? "calc(100vw / 1440 * 33)" : "33px"}
            fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"}
          >
            ${price.toFixed(2)} Token Price
          </Box>
          <Box ml={sm ? "-20px" : 0} width={sm ? "calc(100%  + 35px)" : "unset"}>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="area"
              height={lg ? (window.innerWidth / 1440) * 190 : 190}
            />
          </Box>
          <ToolTip
            width={lg ? "calc(100vw / 1440 * 178)" : sm ? "140px" : "178px"}
            height={lg ? "calc(100vw / 1440 * 119)" : sm ? "100px" : "119px"}
            top={lg ? "calc(100vw / 1440 * 282)" : "282px"}
            left={lg ? "calc(100vw / 1440 * 62)" : sm ? "0" : mm ? "22px" : "62px"}
          >
            <Box lineHeight={"175%"}>
              {curToken.toUpperCase()} Token <br />
              Rising price floor
            </Box>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 24)" : sm ? "18px" : "24px"}
              lineHeight={"120%"}
              letterSpacing={"0.01em"}
              display={"flex"}
              alignItems={"center"}
            >
              <Box mr={lg ? "calc(100vw / 1440 * 11)" : "11px"}>{pricechange.toFixed(0)}%</Box>
              {pricechange >= 0 ? (
                <FaArrowUp fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"} color={"#6EDCB5"} />
              ) : (
                <FaArrowDown fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"} color={"#ea3943"} />
              )}
            </Box>
          </ToolTip>
          <ToolTip
            width={lg ? "calc(100vw / 1440 * 214)" : sm ? "170px" : "214px"}
            height={lg ? "calc(100vw / 1440 * 119)" : sm ? "100px" : "119px"}
            top={lg ? "calc(100vw / 1440 * 282)" : "282px"}
            right={lg ? "calc(100vw / 1440 * 64)" : sm ? "0px" : "64px"}
          >
            <Box lineHeight={"175%"}>
              {curToken.toUpperCase()} Token <br />
              Treasury value
            </Box>
            <Box
              fontSize={lg ? "calc(100vw / 1440 * 24)" : sm ? "18px" : "24px"}
              lineHeight={"120%"}
              letterSpacing={"0.01em"}
              display={"flex"}
              alignItems={"center"}
            >
              <Box mr={lg ? "calc(100vw / 1440 * 11)" : "11px"}>
                ${numberWithCommas((treasuryTokenBalance * price).toFixed(2))}
              </Box>
              {pricechange >= 0 ? (
                <FaArrowUp fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"} color={"#6EDCB5"} />
              ) : (
                <FaArrowDown fontSize={lg ? "calc(100vw / 1440 * 14)" : "14px"} color={"#ea3943"} />
              )}
            </Box>
          </ToolTip>
        </PricePanel>
      </Box>
      <Box
        display={"flex"}
        justifyContent={md ? "space-evenly" : "space-between"}
        flexWrap={md ? "wrap" : "unset"}
        mt={lg ? "calc(100vw / 1440 * 220)" : md ? "100px" : "220px"}
      >
        {abouts.map((data, i) => {
          return (
            <Item
              mb={md ? "30px" : 0}
              width={md ? "320px" : "unset"}
              data-aos="fade-up"
              data-aos-delay={300 * i}
              key={i}
            >
              <Box
                display={"flex"}
                minWidth={lg ? "calc(100vw / 1440 * 80)" : sm ? "60px" : "80px"}
                maxWidth={lg ? "calc(100vw / 1440 * 80)" : sm ? "60px" : "80px"}
                minHeight={lg ? "calc(100vw / 1440 * 80)" : sm ? "60px" : "80px"}
                maxHeight={lg ? "calc(100vw / 1440 * 80)" : sm ? "60px" : "80px"}
              >
                <img src={data.url} alt={""} width={"100%"} height={"100%"} />
              </Box>
              <Box ml={lg ? "calc(100vw / 1440 * 24)" : "24px"}>
                <Box
                  fontSize={lg ? "calc(100vw / 1440 * 40)" : sm ? "24px" : "34px"}
                  lineHeight={"150%"}
                  fontWeight={"bold"}
                >
                  {data.value}
                </Box>
                <Box
                  fontSize={lg ? "calc(100vw / 1440 * 16)" : "16px"}
                  lineHeight={"175%"}
                  mt={lg ? "calc(100vw / 1440 * 4)" : sm ? "-5px" : "4px"}
                  color={"#E0E0E0"}
                >
                  {data.text}
                </Box>
              </Box>
            </Item>
          );
        })}
      </Box>
      <StakingPanel data-aos="fade-up" data-aos-delay={300}>
        <Box fontSize={lg ? "calc(100vw / 1440 * 32)" : sm ? "24px" : "32px"} fontWeight={"bold"}>
          Staking, Farming & NFTS Available
        </Box>
        <Box fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"} fontWeight={500}>
          Take advantage of passive income opportunities through the WPT dAPP.
        </Box>
        <Box
          mt={lg ? "calc(100vw / 1440 * 62)" : sm ? "30px" : "62px"}
          display={"flex"}
          justifyContent={"flex-end"}
          position={"relative"}
          zIndex={10}
        >
          <a href={"https://wptdapp.io"} target={"_blank"} rel="noreferrer">
            <Button
              type="secondary"
              width={lg ? "calc(100vw / 1440 * 190)" : sm ? "150px" : "190px"}
              height={lg ? "calc(100vw / 1440 * 59)" : sm ? "45px" : "59px"}
              fontSize={lg ? "calc(100vw / 1440 * 16)" : sm ? "14px" : "16px"}
            >
              Visit Dashboard
            </Button>
          </a>
        </Box>
        <EthereumVector />
      </StakingPanel>
      <Vector />
    </StyledContainer>
  );
}

const Vector = styled(Box)`
  position: absolute;
  left: calc(100vw / 1440 * 151);
  top: calc(100vw / 1440 * 0);
  background-image: url("/images/holders/vector.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 686);
  height: calc(100vw / 1440 * 229);
  @media screen and (max-width: 1440px) {
    left: 151px;
    width: 686px;
    height: 229px;
  }
`;

const EthereumVector = styled(Box)`
  position: absolute;
  right: calc(100vw / 1440 * 12);
  top: calc(100vw / 1440 * 11);
  background-image: url("/images/holders/ethereumvector.png");
  background-size: 100% 100%;
  width: calc(100vw / 1440 * 119);
  height: calc(100vw / 1440 * 186);
  @media screen and (max-width: 1440px) {
    right: 12px;
    top: 11px;
    width: 119px;
    height: 186px;
  }
  @media screen and (max-width: 550px) {
    width: 60px;
    height: 100px;
  }
`;

const StakingPanel = styled(Box)`
  margin-top: calc(100vw / 1440 * 107);
  margin-right: calc(100vw / 1440 * 16);
  padding: calc(100vw / 1440 * 17) calc(100vw / 1440 * 92) calc(100vw / 1440 * 21)
    calc(100vw / 1440 * 43);
  background: #3671e9;
  border-radius: calc(100vw / 1440 * 16);
  position: relative;
  @media screen and (max-width: 1440px) {
    margin-top: 107px;
    margin-right: 16px;
    padding: 17px 92px 21px 43px;
    border-radius: 16px;
  }
  @media screen and (max-width: 900px) {
    max-width: 650px;
    margin: 80px auto 0 auto;
    padding: 17px 30px 21px 30px;
  }
  @media screen and (max-width: 550px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ToolTip = styled(Box)`
  background: #491f98;
  backdrop-filter: blur(calc(100vw / 1440 * 250));
  /* Note: backdrop-filter has minimal browser support */

  border-radius: calc(100vw / 1440 * 16);
  position: absolute;
  padding: calc(100vw / 1440 * 12) calc(100vw / 1440 * 15);
  font-size: calc(100vw / 1440 * 16);
  font-weight: 700;
  @media screen and (max-width: 1440px) {
    backdrop-filter: blur(250px);

    border-radius: 16px;
    padding: 12px 15px;
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
    padding: 12px;
  }
`;

const PricePanel = styled(Box)`
  background: #35068c;
  width: calc(100vw / 1440 * 580);
  height: calc(100vw / 1440 * 298);
  border-radius: calc(100vw / 1440 * 16);
  padding: calc(100vw / 1440 * 29) calc(100vw / 1440 * 40) calc(100vw / 1440 * 48)
    calc(100vw / 1440 * 40);
  .apexcharts-tooltip {
    color: white;
  }
  .apexcharts-tooltip.apexcharts-theme-light {
    background: ${({ down }) =>
      down === "true" ? "rgba(234, 57, 67, 0.5)" : "rgba(110, 220, 181, 0.5)"};
  }
  .apexcharts-tooltip-title {
    display: none;
  }
  .apexcharts-xaxistooltip {
    display: none;
  }
  .apexcharts-tooltip.apexcharts-theme-light {
    border: none;
  }
  .apexcharts-tooltip-text-y-label {
    display: none;
  }
  .apexcharts-tooltip-marker {
    margin-right: 0;
  }
  .apexcharts-tooltip-text-y-value {
    font-size: 16px;
  }
  position: relative;
  font-family: "Inter";
  @media screen and (max-width: 1440px) {
    width: 580px;
    height: 298px;
    border-radius: 16px;
    padding: 29px 40px 48px 40px;
  }
  @media screen and (max-width: 1250px) {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 500px;
  }
`;

const StyledContainer = styled(Box)`
  padding: 0 calc(100vw / 1440 * 120);
  position: relative;
  background: #2b076e;
  padding-top: calc(100vw / 1440 * 100);
  @media screen and (max-width: 1100px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 100px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  overflow: hidden;
`;

const Item = styled(Box)`
  display: flex;
  align-items: center;
`;

export default Holders;
