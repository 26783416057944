export const MULTICALL_ADDR = "0xeefba1e63905ef1d7acba5a8513c70307c1ce441";
export const LOCK_ADDR = "0x9132AdAf85802A025d8F3830126E3E9894a451f9";
export const FARM_ADDR = "0x2040726132171f2F9472b1Bd0E5CeAdb3BAE002C";
export const PAIR_ADDR = "0x638996A1327AC6FC0770d543374E0055EfFa2B1d";
export const BUYBACK_ADDR = "0x547ED2Ed1c7b19777dc67cAe4A23d00780a26c36";
export const TOKEN_ADDRS = {
  wpt: "0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea",
  bacon: "0x186785D4cba7263532F1da9bBeC3Da612dd6D085",
};

export const TOKEN_LIST = ["wpt", "bacon"];
